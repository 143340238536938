$spacer: 2rem;

$font-family-sans-serif: "Lato", sans-serif;
$font-size-root: 18px;

$gray-dark:                 #373a3c !default;
$gray:                      #55595c !default;
$gray-light:                #8a9999 !default;
$gray-lighter:              #e8eded !default;
$gray-lightest:             #f5fafa !default;

$brand-primary: #dd6531;
$brand-info: #00a5e6;
$brand-success: #00ad7a;
$brand-danger: #e60000;

$body-color: #909e9e;

$headings-color: #667070;
$headings-margin-top: $spacer;
$headings-margin-bottom: $spacer;

$border-width: 1px;

$state-success-text:             #ffffff;
$state-success-bg:               $brand-success;
$state-success-border:           $state-success-bg;

$state-info-text:             #ffffff;
$state-info-bg:               $brand-info;
$state-info-border:           $state-info-bg;

$state-warning-text:             #8a6d3b !default;
$state-warning-bg:               #fcf8e3 !default;
$mark-bg:                        $state-warning-bg !default;
$state-warning-border:           darken($state-warning-bg, 5%) !default;

$state-danger-text:             #ffffff;
$state-danger-bg:               $brand-danger;
$state-danger-border:           $state-danger-bg;

$navbar-padding-vertical: 2rem;

$form-group-margin-bottom: 1rem;

@import "node_modules/bootstrap/scss/bootstrap";

body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-feature-settings: 'liga', 'kern';
}

h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    a:hover {
        text-decoration: none;
    }
}

.navbar {
    margin-bottom: $navbar-padding-vertical;
}

@include media-breakpoint-down(md) {
    .navbar .navbar-toggleable-md {
        clear: left;
        .nav-item, .navbar-nav {
            width: 100%;
            margin-left: 0;
        }
    }
}

.nav-pills {
    margin-bottom: $navbar-padding-vertical;
}

.pagination {
    a:hover,
    a:focus {
        text-decoration: none;
        outline: none;
    }
}

.section {
    padding-top: $spacer;
    margin-top: $spacer;
    margin-bottom: $spacer;
    border-top: 4px solid $gray-lightest;
}

footer.navbar {
    margin-top: $spacer*2;
    margin-bottom: 0;
}

.hero {
    background: $gray-dark;
    color: #fff;
    margin-top: -2rem;
    padding-top: 4rem;
    padding-bottom: 2rem;
    h1 {
        color: #fff;
        position:relative;
        &::after {
            content: "";
            display: block;
            position: absolute;
            bottom: -20px;
            left: 0;
            width: 120px;
            height: 4px;
            background: $gray;
        }
    }
    .btn {
        font-weight: bold;
        border-width: 2px;
    }
}

.section-icon {
    @include make-col();
    @include make-col-span(2);
    @include media-breakpoint-down(xs) {
        @include make-col-span(4);
        @include make-col-offset(4);
        margin-bottom: 2rem;
    }
}

.section-content {
    @include make-col();
    @include make-col-span(10);
    @include media-breakpoint-down(xs) {
        @include make-col-span(12);
    }
}

.embed-responsive svg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.Post {
    &__title {
        a {
            color: $brand-info;
        }
    }
}

// Fix other input types
select, input[type="color"] {
    height: 42.5px;
}
